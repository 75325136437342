<template>
  <v-container id="report" fluid tag="section" class="py-0 my-0">
    <v-row>
      <v-col cols="12" md="12" :key="getSeries().length">
        <MFBarChartStacked :title="'ORDERS'" :series="getSeries()" :minSeries="minSeries"></MFBarChartStacked>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" :key="1000+getCollections().length">
        <MFLineChart :title="'Months comparison (last 3 years)'" :xAxis="getMonths()" :collections="getCollections()"
                     :steps="getSteps()"></MFLineChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" :key="2000+getCountries().length">
        <MFDonutChart :title="'COUNTRIES'" :series="getCountries()"></MFDonutChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" :key="2000+getItalians().length">
        <MFAreaCharts :title="'ITALIA'" :series="getItalians()"></MFAreaCharts>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {call, get, sync} from 'vuex-pathify';
import MFLineChart from '../components/charts/MFLineChart.vue';
import MFBarChartStacked from '../components/charts/MFBarChartStacked.vue';
import MFDonutChart from '../components/charts/MFDonutChart.vue';
import MFAreaCharts from '../components/charts/MFAreaCharts';

export default {
  components: {
    MFLineChart,
    MFBarChartStacked,
    MFDonutChart,
    MFAreaCharts
  },
  name: 'Report',
  methods: {
    getCollections: function () {
      // controllo per verificare la presenza di statistiche
      if (!this.monthlyReport) return [];
      // se presenti, restituisco la mappa delle chiavi (gli anni) a cui faccio corrispondere un oggetto che ha come name l'anno e come collection i totalAmount
      return Object.keys(this.monthlyReport).map(el => ({
        name: el,
        collection: this.monthlyReport[el].map(x => x.totalAmount)
      }));
    },
    getCountries: function () {
      // controllo per verificare la presenza di statistiche
      if (!this.nationalReport) return {};
      // let clone = JSON.parse(JSON.stringify(this.nationalReport));
      // Object.keys(this.nationalReport).forEach(el => {
      //   clone[el] = clone[el].map(elem => ({
      //     category: elem.countryCode,
      //     value: elem.totalAmount
      //   }));
      // });


      return this.nationalReport;
    },
    getSteps: function () {
      // func accessoria per gestire tagli orizzontali sul grafico a linee.
      return []; // [{ name : "eg: BUDGET 2021", value : 12000000, color: "#00FF00" }];
    },
    setSeries: function () {
      if (!this.monthlyReport) return;
      let currentStep = this.monthlyReport[this.$moment().year()].length;
      if (!currentStep) return;
      // identifico le 3 label che mi rappresentano l'intervallo di mesi dall'inizio dell'anno al currentStep, il current step e dal currentStep alla fine dell'anno
      let closedMonths = `${currentStep == 1 ? null : currentStep == 2 ? `${this.months[0]}` : `${this.months[0]}-${this.months[currentStep - 2]}`}`;
      let currentMonth = `${this.months[currentStep - 1]}`;
      let eoy = `${currentStep == 12 ? null : `${this.months[currentStep]}-${this.months[this.months.length - 1]}`}`;
      this.series.length = 0;
      // moltiplicatore per gestire la base di partenza dei valori dell'asse y del grafico
      let minAdapter = 0.6;
      Object.keys(this.monthlyReport).forEach(el => {
        // identifico i 2 valori parziali da inserire in serie
        let dataPart1 = this.monthlyReport[el].sort((a, b) => a.month - b.month).reduce((a, b, i) => {
          a = +a || 0;
          a += i < (currentStep - 1) ? (+b.totalAmount || 0) : 0;
          return +(a.toFixed(2))
        }, 0);
        let dataPart2 = this.monthlyReport[el].sort((a, b) => a.month - b.month).reduce((a, b, i) => {
          a = +a || 0;
          a += i == (currentStep - 1) ? (+b.totalAmount || 0) : 0;
          return +(a.toFixed(2))
        }, 0);
        let dataPart3 = this.monthlyReport[el].sort((a, b) => a.month - b.month).reduce((a, b, i) => {
          a = +a || 0;
          a += i > (currentStep - 1) ? (+b.totalAmount || 0) : 0;
          return +(a.toFixed(2))
        }, 0);
        // e compongo gli oggetti da rappresentare
        let part1 = {
              name: closedMonths,
              stack: `${el}`,
              data: [dataPart1],
              tooltip: {visible: true, template: `${closedMonths} ${el}: €${this.getFormattedPrice(dataPart1)}`}
            },
            part2 = {
              name: currentMonth,
              stack: `${el}`,
              data: [dataPart2],
              tooltip: {visible: true, template: `${currentMonth} ${el}: €${this.getFormattedPrice(dataPart2)}`}
            },
            part3 = {
              name: eoy,
              stack: `${el}`,
              data: [dataPart3],
              tooltip: {visible: true, template: `${eoy} ${el}: €${this.getFormattedPrice(dataPart3)}`}
            };
        // infine determino (a aggiorno) il valore minimo dal quale far partire la rappresentazione
        if (!!(+part1.data[0]) && this.minSeries < (+part1.data[0] * minAdapter)) this.minSeries = +part1.data[0] * minAdapter;
        if (!!(+part2.data[0]) && this.minSeries < (+part2.data[0] * minAdapter)) this.minSeries = +part2.data[0] * minAdapter;
        if (!!(+part3.data[0]) && this.minSeries < (+part3.data[0] * minAdapter)) this.minSeries = +part3.data[0] * minAdapter;
        // e lo arrotondo per difetto ai 500k
        this.minSeries = Math.floor(this.minSeries / 500000) * 500000;
        this.series.push(part1, part2, part3);
        this.series.filter(x => !!x.name);
      });
    },
    getFormattedPrice: function (value) {
      return `${this.$numerals(value, `0,0`)}`.replaceAll(`,`, `.`);
    },
    getSeries: function () {
      return this.series;
    },
    getMonths: function () {
      return this.months;
    },
    getYears: function () {
      !!this.monthlyReport ? Object.keys(this.monthlyReport) : [];
    },
    getItalians: function () {
      return this.italianReport;
    },
    initialize() {
      // this.apiStatisticalTagGet();
      this.apiGeonameCountryGet()
          .then(res => {
            // this.countries.length = 0;
            // this.countries.push(...res);
            // debugger
            // let italy = res.find(el => el.iso == 'IT');
            // this.apiGeonameCountryIsocodeRegionsGet('IT');
            // .then(response => {
            //   this.regions.length = 0;
            //   this.regions.push(...response);
            // })
          })
      this.apiGeonameCountryIsocodeRegionsGet('IT');
      // this.apiGeonameRegionIsocodeProvincesGet
      // report del fatturato
      this.apiOrderStatisticalDataFilteredPost({
        orderDate: `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().endOf("year").format('DDMMYYYY')}`,
        // orderDate : `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().startOf("month").subtract(1, 'day').format('DDMMYYYY')}`,
        groupByYear: true,
        groupByMonth: true,
      })
          .then(res => {
            this.monthlyReport = res.reduce((a, b) => {
              a[b.year] = a[b.year] || [];
              a[b.year].push(b);
              return a
            }, {});
            Object.values(this.monthlyReport).every(el => el.sort((a, b) => a.month - b.month));
            this.setSeries();
          });
      // report per nazione
      this.apiOrderStatisticalDataFilteredPost({
        orderDate: `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().endOf("year").format('DDMMYYYY')}`,
        // orderDate : `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().startOf('month').subtract(1, 'day').format('DDMMYYYY')}`,
        groupByYear: true,
        groupByCountryCode: true,
        groupByContinent: true,
      })
          .then((res) => {
            console.log("OrderStatisticalDataFilteredPost", res);
            this.nationalStats.length = 0;
            this.nationalStats.push(...res);
            // INIZIO - sezione da eliminare - MOCKUP
            // let alfa = "ABCDEFGHILMNOPQRSTUVZabcdefghilmnopqrstuvz",
            //     temp = res[2],
            //     collection = [];

            // for(let i = 2019; i < 2022 ; i++) {
            //   for(let j = 0; j<20 ; j++) {
            //     collection.push({
            //       ...temp,
            //       continent: `${alfa[j%5]}`,
            //       countryCode: `${alfa[j] + alfa[j+2]}`,
            //       year: `${i}`,
            //       totalAmount: Math.round(Math.random() * 99999)
            //     });
            //   }
            // }
            // this.nationalReport = collection.reduce((a, b) => { a[b.year] = a[b.year] || []; a[b.year].push(b); return a }, {});
            // FINE SEZIONE

            // da ripristinare
            this.nationalReport = res.reduce((a, b) => {
              a[b.year] = a[b.year] || [];
              a[b.year].push({...b, countryCode: this.countries.find(el => el.iso == b.countryCode)?.country});
              return a
            }, {});
          })
      // report italia
      this.apiOrderStatisticalDataFilteredPost({
        orderDate: `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().endOf("year").format('DDMMYYYY')}`,
        // orderDate : `${this.$moment().subtract(2, 'year').startOf("year").format('DDMMYYYY')}..${this.$moment().startOf('month').subtract(1, 'day').format('DDMMYYYY')}`,
        groupByYear: true,
        groupByMonth: true,
        groupByRegionCode: true,
        countryCode: "IT",
      })
          .then((res) => {
            this.italianStats.length = 0;
            this.italianStats.push(...res);

            // INIZIO - sezione da eliminare - MOCKUP
            // let alfa = "ABCDEFGHILMNOPQRSTUVZabcdefghilmnopqrstuvz",
            //     collection = [];
            // for(let i = 2019; i < 2022 ; i++) {
            //   for(let j = 0; j<20 ; j++) {
            //     for(let k = 0; k<12 ; k++) {
            //       collection.push({
            //         year: `${i}`,
            //         countryCode: "IT",
            //         regionCode: `${alfa[j] + alfa[j+2]}`,
            //         month: `${k}`,
            //         totalQuantity: 0,
            //         totalAmount: Math.round(Math.random() * 99999)
            //       });
            //     }
            //   }
            // }
            // this.italianReport = collection.reduce((a, b) => { a[b.year] = a[b.year] || []; a[b.year].push(b); return a }, {});
            // FINE SEZIONE
            // da ripristinare
            this.italianReport = res.reduce((a, b) => {
              a[b.year] = a[b.year] || [];
              a[b.year].push({...b, regionCode: this.regions.find(el => el.regionId == b.regionCode)?.regionName});
              return a
            }, {});
          })
    },
    ...call('geoname/*'),
    ...call('statistics/*')
  },
  data: () => ({
    xAxis: [],
    goalsData: [],
    series: [],
    globalTarget: 0,
    minSeries: 0,
    maxSeries: Infinity,
    globalSales: 0,
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    monthlyReport: null,
    nationalStats: [],
    nationalReport: [],
    italianStats: [],
    italianReport: [],
    // countries: [],
    // regions: [],
  }),
  computed: {
    sales: get('sales/sales'),
    countries: get('geoname/countries'),
    regions: get('geoname/countryRegions'),
    provinces: get('geoname/regionProvinces'),
    goalResponse: sync('statistics/zoneGoalCollection'),
    orderResponse: sync('statistics/orderStatsCollection'),
    filters: sync('statistics/filter'),
    statisticalTags: sync('statistics/statisticalTagCollection')
  },
  created() {
    this.initialize();
  },

}
</script>
